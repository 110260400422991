import React from 'react';
import { PageProps } from 'gatsby';
import { Typography } from 'antd';
import Layout from '@/components/layout';

const NotFound: React.FC<PageProps> = () => (
  <Layout expand={false}>
    <Typography.Title level={1}>404 - Page introuvable</Typography.Title>
    <Typography.Title level={3}>
      La page demandée n&apos;existe pas ou plus.
    </Typography.Title>
  </Layout>
);

export default NotFound;
